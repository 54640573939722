import Animated, { Revealed } from 'components/animated'
import Box, { Card, Content, Flex, Grid, Section } from 'components/box'
import Button, { SecondaryButton } from 'components/button'
import CallToAction from 'components/sections/cta'
import convertImageMap from 'helpers/convertImageMap'
import Img from 'gatsby-image'
import IO from 'components/io'
import Layout from 'components/layout'
import Products from 'components/sections/products'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Screenshot from 'components/screenshot'
import Text, { Caps, Heading, Lead } from 'components/text'
import { AppContext } from 'store/app'
import { Circle } from 'components/icon'
import { graphql } from 'gatsby'

const Browser = ({ data }) => {
  const { focusSubscriptionForm } = useContext(AppContext).appState
  const scrollToLearnMore = () =>
    document.querySelector('#intro').scrollIntoView({ behavior: 'smooth' })
  const { page } = data
  const icons = convertImageMap(page.icons)
  const images = convertImageMap(page.images)
  const screens = convertImageMap(page.screens)
  const websites = convertImageMap(page.websites)

  return (
    <Layout pageTitle={page.title}>
      <Section hero>
        <Content my={5}>
          <Grid columns={[1, 1, 2]} gridGap={[4, 4, 0]}>
            <Box
              gridRow={[2, 2, 'auto']}
              textAlign={['center', 'center', 'left']}
            >
              <Caps
                as={Revealed}
                alignItems="center"
                color="purple"
                display="inline-flex"
                fontSize={[5, 4]}
                ml={[-3, -3, 0]}
                my={3}
              >
                <Circle mr={3} opacity={0.5} />
                {page.title}
              </Caps>
              <Heading
                as={Revealed}
                forwardedAs="h1"
                delay={1}
                level={0}
                measure="tight"
                mx={['auto', 'auto', 'initial']}
              >
                {page.tagline}
              </Heading>
              <Lead
                as={Revealed}
                forwardedAs="p"
                delay={2}
                fontWeight="medium"
                measure="narrow"
                mb={5}
                mt={3}
                mx={['auto', 'auto', 'initial']}
              >
                {page.description}
              </Lead>
              <Revealed
                containerProps={{ display: 'inline-flex', mb: 2, mr: 2 }}
                delay={4}
              >
                <Button
                  onClick={focusSubscriptionForm}
                  color="purple"
                  size="large"
                >
                  Join Beta
                </Button>
              </Revealed>
              <Revealed containerProps={{ display: 'inline-flex' }} delay={5}>
                <SecondaryButton
                  onClick={scrollToLearnMore}
                  color="purple"
                  size="large"
                >
                  Learn more
                </SecondaryButton>
              </Revealed>
            </Box>
            <Flex
              alignItems="center"
              flexDirection="column"
              justifyContent="flex-start"
              position="relative"
              px={3}
            >
              <Revealed
                as={Circle}
                bottom="10%"
                color="purple-050"
                duration={6}
                left="0"
                position="absolute"
                preserveAspectRatio="xMinYMax meet"
                size="250%"
                zIndex={-1}
              />
              <Box
                as={Revealed}
                bg="purple-100"
                alignItems="center"
                borderRadius={2}
                delay={3}
                duration={7}
                display="flex"
                justifyContent="center"
                p={2}
                position="relative"
                my={3}
              >
                <Circle color="purple" fontSize={4} />
                <Text
                  borderRadius={2}
                  top="70% "
                  left="70%"
                  bg="purple-400"
                  border={2}
                  borderColor="purple-050"
                  px={1}
                  color="white"
                  fontSize={8}
                  fontWeight="bold"
                  lineHeight={2}
                  position="absolute"
                  truncate
                >
                  3 / 5
                </Text>
              </Box>
              <Screenshot
                as={Revealed}
                aspectRatio="5:4"
                containerProps={{
                  maxWidth: 'narrow',
                  width: '100%',
                }}
                delay={4}
                duration={8}
                screen={screens['Browser']}
              />
            </Flex>
          </Grid>
        </Content>
      </Section>

      <Section id="intro" display="flex" justifyContent="center">
        <Content p={0}>
          <Box
            as={Revealed}
            backgroundImage={`url(${images['Background'].fluid.src})`}
            backgroundSize="cover"
            bg="purple-025"
            borderRadius={3}
            duration={6}
            style={{
              backgroundBlendMode: 'screen',
            }}
          >
            <Box
              backgroundImage={`url(${images['Background'].fluid.src})`}
              backgroundSize="cover"
              bg="purple"
              color="white"
              p={[4, 5]}
              style={{
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              <Heading level={2} measure="normal">
                The Idea
              </Heading>
              <Lead fontWeight="medium" measure="normal" mt={2}>
                What if you could opt out of website features that don't respect
                your intent?
              </Lead>
              <Text
                as="p"
                color="neutral-500"
                fontSize={4}
                measure="wide"
                mt={4}
              >
                Netflix's autoplay, maybe? The Facebook feed? What about
                YouTube's creepily engaging recommendations?
              </Text>
              <Text
                as="p"
                color="neutral-500"
                fontSize={4}
                measure="wide"
                mt={3}
              >
                Intent Browser puts the user back in control by letting them
                turn off these kinds of features. The goal is to make this
                option available to a lot of people regardless of technical
                background.
              </Text>
            </Box>
          </Box>
        </Content>
      </Section>

      <Section>
        <Content my={[5, 5, 6]} textAlign="center">
          <Heading as={Revealed} forwardedAs="h2" level={2} mt={4}>
            How it works
          </Heading>
          <Lead as={Revealed} forwardedAs="p" delay={1} mt={1}>
            Simple and straightforward
          </Lead>
          <IO>
            {({ isVisible, hasBeenVisible }) => (
              <Grid
                columns={[1, 2, 3]}
                gridGap={[2, 3, 4]}
                mt={[4, 4, 5]}
                textAlign="center"
              >
                <Card
                  as={Animated}
                  borderRadius={2}
                  delay={0}
                  elevation={1}
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Flex flexDirection="column" p={3}>
                    <Box mx="auto" mt={3} mb={4} maxWidth="10rem" width="80%">
                      <Box
                        bg="purple-025"
                        borderRadius={5}
                        width="100%"
                        pb="100%"
                        position="relative"
                      >
                        <Box
                          bottom="0"
                          left="0"
                          m="auto"
                          position="absolute"
                          right="0"
                          size="50%"
                          top="0"
                        >
                          <Img {...icons['Icon']} />
                        </Box>
                      </Box>
                    </Box>
                    <Heading level={4} lineHeight={2}>
                      Know at a glance
                    </Heading>
                    <Text
                      color="neutral-400"
                      as="p"
                      measure="normal"
                      mt={1}
                      mx="auto"
                    >
                      See how many switches are active and available on the
                      current website.
                    </Text>
                  </Flex>
                </Card>
                <Card
                  as={Animated}
                  borderRadius={2}
                  delay={1}
                  elevation={1}
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Flex flexDirection="column" p={3}>
                    <Box mx="auto" mt={3} mb={4} maxWidth="10rem" width="80%">
                      <Box
                        bg="purple-025"
                        borderRadius={5}
                        width="100%"
                        pb="100%"
                        position="relative"
                      >
                        <Box
                          bottom="0"
                          left="0"
                          m="auto"
                          position="absolute"
                          right="0"
                          size="50%"
                          top="0"
                        >
                          <Img {...icons['Switch']} />
                        </Box>
                      </Box>
                    </Box>
                    <Heading level={4} lineHeight={2}>
                      Flick the switch
                    </Heading>
                    <Text
                      color="neutral-400"
                      as="p"
                      measure="normal"
                      mt={1}
                      mx="auto"
                    >
                      Simply select which features to turn off. Some features
                      require reloading the page.
                    </Text>
                  </Flex>
                </Card>
                <Card
                  as={Animated}
                  borderRadius={2}
                  delay={2}
                  elevation={1}
                  gridColumn={[1, '1/-1', 3]}
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Flex flexDirection="column" p={3}>
                    <Box mx="auto" mt={3} mb={4} maxWidth="10rem" width="80%">
                      <Box
                        bg="purple-025"
                        borderRadius={5}
                        width="100%"
                        pb="100%"
                        position="relative"
                      >
                        <Box
                          bottom="0"
                          left="0"
                          m="auto"
                          position="absolute"
                          right="0"
                          size="50%"
                          top="0"
                        >
                          <Img {...icons['Sparkles']} />
                        </Box>
                      </Box>
                    </Box>
                    <Heading level={4} lineHeight={2}>
                      Enjoy the clarity
                    </Heading>
                    <Text
                      color="neutral-400"
                      as="p"
                      measure="normal"
                      mt={1}
                      mx="auto"
                    >
                      Browse without features you don't intend to use. Request
                      switches you need.
                    </Text>
                  </Flex>
                </Card>
              </Grid>
            )}
          </IO>
        </Content>
      </Section>

      <Section bg="neutral-050">
        <Content my={[3, 4, 5]}>
          <Grid columns={[1, 2]} gridGap={4} alignItems="center">
            <Box textAlign={['center', 'left']}>
              <Heading as={Revealed} forwardedAs="h2" level={2} mt={4}>
                Website support
              </Heading>
              <Lead
                as={Revealed}
                forwardedAs="p"
                delay={1}
                measure="narrow"
                mt={1}
                mx={['auto', 0]}
              >
                We will be adding switches for the most popular websites upon
                launch, then add new ones based on community feedback.
              </Lead>
            </Box>
            <IO>
              {({ isVisible, hasBeenVisible }) => (
                <Grid
                  columns={3}
                  gridGap="8%"
                  maxWidth={['narrow', 'normal']}
                  mx="auto"
                >
                  <Box
                    as={Animated}
                    delay={1}
                    bg="facebook"
                    borderRadius={5}
                    isVisible={isVisible || hasBeenVisible}
                    width="100%"
                    pb="100%"
                    position="relative"
                  >
                    <Box
                      bottom="0"
                      left="0"
                      m="auto"
                      position="absolute"
                      right="0"
                      size="75%"
                      top="0"
                    >
                      <Img {...websites['Facebook']} />
                    </Box>
                  </Box>
                  <Box
                    as={Animated}
                    delay={3}
                    bg="instagram"
                    borderRadius={5}
                    isVisible={isVisible || hasBeenVisible}
                    width="100%"
                    pb="100%"
                    position="relative"
                  >
                    <Box
                      bottom="0"
                      left="0"
                      m="auto"
                      right="0"
                      size="75%"
                      top="0"
                      position="absolute"
                    >
                      <Img {...websites['Instagram']} />
                    </Box>
                  </Box>
                  <Box
                    as={Animated}
                    delay={5}
                    bg="amazon"
                    borderRadius={5}
                    isVisible={isVisible || hasBeenVisible}
                    width="100%"
                    pb="100%"
                    position="relative"
                  >
                    <Box
                      bottom="0"
                      left="0"
                      m="auto"
                      position="absolute"
                      right="0"
                      size="75%"
                      top="0"
                    >
                      <Img {...websites['Amazon']} />
                    </Box>
                  </Box>
                  <Box
                    as={Animated}
                    delay={2}
                    bg="reddit"
                    borderRadius={5}
                    isVisible={isVisible || hasBeenVisible}
                    width="100%"
                    pb="100%"
                    position="relative"
                  >
                    <Box
                      bottom="0"
                      left="0"
                      m="auto"
                      right="0"
                      size="75%"
                      top="0"
                      position="absolute"
                    >
                      <Img {...websites['Reddit']} />
                    </Box>
                  </Box>
                  <Box
                    as={Animated}
                    delay={4}
                    bg="twitter"
                    borderRadius={5}
                    isVisible={isVisible || hasBeenVisible}
                    width="100%"
                    pb="100%"
                    position="relative"
                  >
                    <Box
                      bottom="0"
                      left="0"
                      m="auto"
                      position="absolute"
                      right="0"
                      size="75%"
                      top="0"
                    >
                      <Img {...websites['Twitter']} />
                    </Box>
                  </Box>
                  <Box
                    as={Animated}
                    delay={6}
                    bg="youtube"
                    borderRadius={5}
                    isVisible={isVisible || hasBeenVisible}
                    width="100%"
                    pb="100%"
                    position="relative"
                  >
                    <Box
                      bottom="0"
                      left="0"
                      m="auto"
                      right="0"
                      size="75%"
                      top="0"
                      position="absolute"
                    >
                      <Img {...websites['YouTube']} />
                    </Box>
                  </Box>
                </Grid>
              )}
            </IO>
          </Grid>
        </Content>
      </Section>
      <CallToAction />
      <Products />
    </Layout>
  )
}

Browser.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Browser

export const query = graphql`
  query BrowserPage {
    page: browserJson {
      title
      tagline
      description
      icons {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      images {
        title
        image {
          childImageSharp {
            fluid(toFormat: JPG, jpegProgressive: true) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      screens {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      websites {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
